.MenuFooter {
  background-color: transparent;
  border-top: 1px solid var(--border-color);
  border-inline-end: none !important;
}

.TermEvent {
  background-color: var(--main-bg) !important;
  text-align: center;
}

.TermEvent svg {
  color: black !important;
}

.TermEvent:hover {
  background-color: #cfe8fc !important;
}

.ListItemTitle {
  cursor: pointer;
}

.ListItemTitle:hover {
  color: var(--main-color);
}

.MarginTop {
  margin-top: 16px;
}

.MarginBottom {
  margin-bottom: 16px !important;
}

.NoPadding {
  padding: 0;
}

.MarginRight {
  margin-right: 16px;
}

.LargeMarginBottom {
  margin-bottom: 24px;
}

.CursorPointer {
  cursor: pointer;
}

.Width100 {
  width: 100% !important;
}

.PaddingFooter {
  padding: 16px 0;
}

.CalendarEventCard {
  height: 100%;
  padding: 8px;
  border-left: 5px solid red !important;
  position: relative;
}

.CalendarEventCard_A {
  height: 100%;
  padding: 8px;
  border-left: 5px solid #30b930 !important;
  position: relative;
}

.CalendarEventCard_N {
  height: 100%;
  padding: 8px;
  border-left: 5px solid yellow !important;
  position: relative;
}

.CalendarEventTime {
  margin-bottom: 8px;
}

.CalendarEventPrice {
  position: absolute;
  right: 8px;
  top: 8px;
}

.CustomerDivider {
  height: 24px !important;
  margin-bottom: 0 !important;
}

.CalendarFilter {
  height: 32px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.CalendarFilterHide {
  margin-top: -16px;
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

/* LOGIN PAGE */
.LoginWrapper {
  width: 100%;
  height: 100vh;
  background: url("../assets/images/world_map.svg") fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.LoginAuth {
  background-color: rgba(28, 83, 142, 0.6);
  border-radius: 12px;
  max-width: 350px;
  padding: 16px;
}

.LoginWrapper small {
  color: rgba(255, 255, 255, 0.25);
  display: block;
  margin-top: 16px;
}

.LoginAuth > span {
  margin: auto;
  display: block;
  margin-bottom: 16px;
  font-size: 42px;
  color: white;
}

/* FALLBACK */
.Fallback {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

/* DASHBOARD */
.DashAvatar {
  width: 48px;
  height: 48px;
  display: none;
}

.CardGrid {
  width: 100% !important;
}

.DashNextReservation {
  font-size: 48px;
  margin-bottom: 12px;
}

.DashNextReservationCard {
  text-align: center !important;
}

.DashStat {
  text-align: center;
  margin-top: 24px;
}

.NoPaddingCard > div:nth-child(2) {
  padding: 0 !important;
}

.ListWrapper {
  background-color: white;
  width: 100%;
}

.ListWrapperNoBorder {
  background-color: white;
  width: 100%;
  border: unset !important;
}

.ListItem:hover {
  cursor: pointer;
  background-color: rgba(230, 244, 255, 0.5) !important;
}

.ListItemRemove:hover {
  cursor: pointer;
  background-color: rgba(255, 230, 230, 0.5) !important;
}

.ListItemSelected {
  cursor: pointer;
  background-color: rgb(230, 244, 255);
}

.ListItemSelected:hover {
  background-color: rgba(255, 174, 181, 0.25) !important;
}

@media (min-width: 768px) {
  .DashAvatar {
    display: block;
  }
  .CardGrid {
    width: 50% !important;
  }
}

@media (min-width: 992px) {
  .CardGrid {
    width: 33.33% !important;
  }
  .DashStat {
    text-align: right;
    margin-top: unset;
  }
  .DashAvatar {
    width: 72px;
    height: 72px;
  }
  .DashNextReservation {
    font-size: 72px;
    margin-bottom: 24px;
  }
}

.RedDeleteIcon {
  color: red;
  cursor: pointer;
}

.RedDeleteIcon:hover {
  opacity: 0.2;
}

.MinButtonWitdth {
  min-width: 32px !important;
}

/* SETTING */
.SettingCardInner {
  box-shadow: none !important;
  width: 100%;
}

@media (min-width: 768px) {
  .SettingCard > div {
    padding-inline: 0 !important;
  }

  .SettingCardInner > div {
    padding-block: 0 !important;
  }
}

.Statistics {
  text-align: center;
}
