:root {
  --main-bg: #f5f5f5;
  --secondary-bg: white;
  --main-color: #1890ff;
  --main-hover-color: #025997;
  --soft-blue-hover-color: #f5f9ff;
  --border-color: rgba(5, 5, 5, 0.06);
}

::-webkit-scrollbar {
  display: none !important;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  margin: 0;
  background-color: var(--main-bg);
}

/* ANT DESIGN UPRAVY */
.ant-page-header {
  padding-inline: unset !important;
  padding-top: 0 !important;
  padding-bottom: 16px !important;
}
.ant-pro-global-footer {
  margin-top: 24px !important;
}
.ant-radio-group {
  min-width: fit-content !important;
}
.ant-input-number-group-wrapper {
  width: 100% !important;
}
.ant-layout-content {
  padding-block: 16px !important;
  padding-inline: 16px !important;
}
.ant-list-header {
  padding-block: unset !important;
}
.ant-list-item-extra {
  display: none;
  margin-block: auto !important;
}
.ant-drawer-content-wrapper {
  max-width: 100%;
}
.ant-card-body {
  padding: 16px !important;
}
.ant-drawer-close {
  position: absolute !important;
  right: 4px;
}
.ant-drawer-header,
.ant-drawer-body,
.ant-drawer-footer {
  padding: 16px !important;
}

@media (min-width: 768px) {
  .ant-layout-content {
    padding-block: 24px !important;
    padding-inline: 24px !important;
  }
  .ant-list-item-extra {
    display: block;
  }
  .ant-drawer-header,
  .ant-drawer-body,
  .ant-drawer-footer {
    padding: 16px 24px !important;
  }
  .ant-drawer-close {
    right: 16px;
  }
  .ant-card-body {
    padding: 24px !important;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}

/* CALENDAR DESIGN UPRAVY */
.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: 600px !important;
}
.fc-scrollgrid {
  background-color: white;
}
.fc-header-toolbar {
  display: none !important;
}
.fc-dayGridMonth-view * {
  border-color: var(--border-color) !important;
}
.fc-daygrid-day,
.fc-col-header-cell {
  border-right: 1px solid rgba(5, 5, 5, 0.03) !important;
  border-bottom: 1px solid rgba(5, 5, 5, 0.1) !important;
}
.fc-col-header-cell {
  background-color: var(--main-bg);
}
.fc-timegrid-slot,
.fc-timegrid-col {
  border-style: dashed !important;
  border-color: var(--border-color) !important;
}
.fc-timeGridWeek-view .fc-timegrid-slot,
.fc-timeGridDay-view .fc-timegrid-slot,
.fc-listWeek-view .fc-timegrid-slot {
  height: 2rem !important;
  vertical-align: top !important;
}
@media (min-width: 768px) {
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    height: fit-content !important;
  }
  .fc-timeGridWeek-view .fc-timegrid-slot,
  .fc-timeGridDay-view .fc-timegrid-slot,
  .fc-listWeek-view .fc-timegrid-slot {
    height: 4rem !important;
  }
}
.fc-timegrid-slot-label-cushion {
  font-size: 10px !important;
}
.fc-timegrid-col-events {
  margin: 0 !important;
}
.fc-timegrid-event-harness {
  margin: 5px !important;
  border-radius: 6px !important;
}
.fc-day-today {
  background-color: rgb(230, 244, 255) !important;
}
.fc-timeGridWeek-view .fc-scrollgrid-sync-inner,
.fc-timeGridDay-view .fc-scrollgrid-sync-inner,
.fc-listWeek-view .fc-scrollgrid-sync-inner,
.fc-col-header-cell .fc-scrollgrid-sync-inner {
  text-transform: uppercase;
  height: 30px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.fc-v-event {
  background-color: var(--main-bg) !important;
  border-color: transparent !important;
}

.fc-daygrid-day-number {
  color: rgba(0, 0, 0, 0.75);
}

.fc-col-header-cell-cushion {
  color: black;
}

.fc-timegrid-now-indicator-container {
  overflow: visible !important;
}

.fc-timegrid-now-indicator-arrow::after {
  content: "";
  display: block;
  width: 100vw;
  border-top: 1px solid rgba(255, 0, 0, 0.5);
}
